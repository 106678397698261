import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const VSTSAltLogo2Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1024 1195V555l256-47v710l-282 232-443-145v146l-299-378 768 122zm1024-982v1622l-512 213L0 1531l1536 228V0l512 213zm-139-23l-275 246 364-209-89-37zm-341 312v93l372-307-372 214zm208-367l-190 199 280-162-90-37zm-208-87v165l167-95-167-70zm0 270l162-170-162 93v77zm0 146l287-258-287 166v92zm0 271l379-315-379 218v97zm0 855v93l372-306-372 213zm0-719l399-325-399 229v96zm0 40v100l401-330-401 230zm0 136v97l358-303-358 206zm0 136v92l404-324-404 232zm0 136v89l382-308-382 219zm0 135v91l413-327-413 236zm0 272v52l384-271-384 219zm-275 29l-143 90 263-72-120-18zm-168-25l-116 74 219-58-103-16zm-157-23l-97 57 195-42-98-15zm-180-27l-57 38 157-23-100-15zm-181-6l84-8-54-8-30 16zm-10-22l-246-37 195 66 51-29zm80 73l60-40-137 14 77 26zm135 45l91-53-174 26 83 27zm134 46l103-66-181 39 78 27zm134 45l141-89-225 60 84 29zm134 45l171-100-259 70 88 30zm118 39l215-152-74-11-229 134 88 29zm155 53l411-325-513 290 102 35zm529-184v-72l-481 272 481-200zm0-204l-457 362 457-258v-104zm0-119l-620 438 620-351v-87zm0-145l-385 317 385-221v-96zm0-125l-388 307 388-222v-85zm0-144l-398 321 398-228v-93zm0-130l-384 307 384-220v-87zm0-150l-373 315 373-214V797zm0-124l-355 293 355-204v-89zm0-136l-370 301 370-212v-89zm0-143l-374 311 374-215v-96zm0-138l-386 319 386-223v-96zM256 1073l-128-22V725l123-166 390-113V298l383 257-768 148v370z" />
    </svg>
  ),
  displayName: 'VSTSAltLogo2Icon',
});

export default VSTSAltLogo2Icon;
