import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const OneDriveAddIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1280 1408h384v128h-384v384h-128v-384H768v-128h384v-384h128v384zm598-477q85 42 127 117t43 170q0 56-19 108t-53 96-81 72-103 39v-132q28-9 52-27t40-44 26-54 10-58q0-36-11-69t-32-60-51-42-69-20q3-58-12-108t-48-88-80-60-107-22q-31 0-68 8t-64 24q-45-66-109-103t-146-38q-22 0-44 2t-43 8q-49 12-89 39t-70 65-47 84-22 95q-48 2-91 17t-76 44-52 69-20 92q0 53 16 103t55 89v152q-34-16-63-39t-53-52H363q-73 0-138-30t-116-80-79-116-30-139q0-115 54-205t162-138q12-78 48-144t92-115 126-77 150-28q24 0 48 2t47 9q71-80 166-125t203-46q49 0 96 9t93 28q141 56 221 164t103 254l1 3q54 13 99 41t79 67 58 87 32 102zm-399-308q-13-69-48-127t-86-101-115-67-134-24q-51 0-98 14t-89 40-76 62-59 81q-29-18-69-27t-73-10q-62 0-115 24t-93 65-62 96-23 116v16q0 8 2 17-49 4-88 25t-67 54-43 75-15 91q0 47 19 89t52 76 74 52 90 20h95q-10-30-13-61t-4-64q0-63 18-116t53-96 82-72 107-49q23-73 63-130t94-98 121-61 144-21q85 0 160 30t136 89l60-8z" />
    </svg>
  ),
  displayName: 'OneDriveAddIcon',
});

export default OneDriveAddIcon;
