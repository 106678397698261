import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AddPhoneIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1402 1307q39 0 76 15t65 43l191 191q28 28 43 65t15 76q0 39-15 76t-43 65l-96 96q-63 62-143 88t-166 26q-107 0-221-33t-228-94-223-142-207-179-180-207-143-223-93-228T0 720q0-77 13-131t40-98 66-85 91-92q28-28 65-43t76-15q39 0 76 15t65 43l191 191q28 28 43 65t15 76q0 39-13 69t-32 56-42 46-42 40-33 37-13 39q0 29 21 50l478 478q21 21 50 21 21 0 39-13t37-32 39-42 46-42 55-33 71-13zm-71 613q60 0 101-11t74-34 65-54 72-73q21-21 21-50 0-30-21-51l-191-191q-21-21-51-21-21 0-39 13t-37 32-39 42-45 42-56 33-70 13q-40 0-76-15t-65-44l-477-477q-28-28-43-64t-16-77q0-39 13-69t32-56 42-45 42-40 33-37 13-40q0-29-21-50L401 405q-21-21-50-21-30 0-51 21-41 41-73 72t-54 64-33 75-12 101q0 95 31 197t86 204 130 202 165 188 188 164 201 131 205 86 197 31zm717-1536v128h-384v384h-128V512h-384V384h384V0h128v384h384z" />
    </svg>
  ),
  displayName: 'AddPhoneIcon',
});

export default AddPhoneIcon;
