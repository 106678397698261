import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SkypeForBusinessLogoFillIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1905 1203q39 61 59 130t20 142q0 105-40 198t-109 162-162 109-198 40q-78 0-141-20t-131-58q-91 17-179 17-124 0-239-32t-214-90-182-141-141-182-91-215-32-239q0-88 17-179-38-67-58-130T64 573q0-105 40-198t109-162 162-109 198-40q78 0 141 20t131 58q91-17 179-17 124 0 239 32t214 90 182 141 141 182 91 215 32 239q0 45-3 90t-15 89z" />
    </svg>
  ),
  displayName: 'SkypeForBusinessLogoFillIcon',
});

export default SkypeForBusinessLogoFillIcon;
