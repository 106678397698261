import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AnalyticsQueryIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2048 1024h-128q0-123-32-237t-90-214-141-182-181-140-214-91-238-32q-124 0-238 32t-213 90-182 141-140 181-91 214-32 238q0 124 32 238t90 213 141 182 181 140 214 91 238 32v128q-142 0-272-36t-245-103-207-160-160-208-103-245-37-272q0-141 36-272t103-245 160-207 208-160T752 37t272-37q141 0 272 36t245 103 207 160 160 208 103 245 37 272zM1024 384q133 0 249 50t204 137 137 203 50 250h-128q0-106-40-199t-110-162-163-110-199-41q-106 0-199 40T663 662 553 825t-41 199q0 103 38 196t112 166l-91 91q-91-91-139-208t-48-245q0-133 50-249t137-204 203-137 250-50zm0 384q53 0 99 20t82 55 55 81 20 100q0 53-20 99t-55 82-81 55-100 20q-53 0-99-20t-82-55-55-81-20-100q0-53 20-99t55-82 81-55 100-20zm0 384q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50q0 27 10 50t27 40 41 28 50 10z" />
    </svg>
  ),
  displayName: 'AnalyticsQueryIcon',
});

export default AnalyticsQueryIcon;
