import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const VoicemailReplyIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1392 1280H384q-80 0-149-30t-122-82-83-122T0 896q0-79 30-149t82-122 122-83 150-30q79 0 149 30t122 82 83 123 30 149q0 72-26 137t-74 119h712q-48-53-74-118t-26-138q0-79 30-149t82-122 122-83 150-30q79 0 149 30t122 82 83 123 30 149q0 68-23 130t-64 112-97 85-123 49l-125-125q12 2 24 3t24 2q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100q0 73 38 134t103 94l-157 156zM384 1152q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100q0 53 20 99t55 82 81 55 100 20zm1408 384q53 0 99 20t82 55 55 81 20 100q0 53-20 99t-55 82-81 55-100 20v-128q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10h-293l162 163-90 90-317-317 317-317 90 90-162 163h293z" />
    </svg>
  ),
  displayName: 'VoicemailReplyIcon',
});

export default VoicemailReplyIcon;
