import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const URLBlockIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1351 1047q-75 34-139 87t-112 121q-52 18-101 21t-103 4v-128h64q66 0 124-25t101-68 69-102 26-125q0-66-25-124t-69-101-102-69-124-26H448q-66 0-124 25t-102 69-69 102-25 124q0 66 25 124t68 102 102 69 125 25h64v128h-64q-93 0-174-35t-142-96-96-142T0 832q0-93 35-174t96-142 142-96 175-36h512q93 0 174 35t142 96 96 142 36 175q0 57-15 111t-42 104zm528 16q-21-38-51-69t-66-52-77-34-85-12h-64V768h64q88 0 167 32t139 90 97 134 42 165q-71-77-166-126zM768 1216q0 53 17 102t48 89 73 70 94 45q-3 20-5 39t-3 39q0 14 2 27t4 28q-78-16-143-57t-114-99-74-131-27-152q0-93 35-174t96-142 142-96 175-36h64v128h-64q-66 0-124 25t-102 69-69 102-25 124zm1280 384q0 93-35 174t-96 143-142 96-175 35q-93 0-174-35t-143-96-96-142-35-175q0-93 35-174t96-143 142-96 175-35q93 0 174 35t143 96 96 142 35 175zm-448 320q66 0 124-25t101-68 69-102 26-125q0-66-25-124t-69-101-102-69-124-26q-66 0-124 25t-102 69-69 102-25 124q0 66 25 124t68 102 102 69 125 25zm-192-256v-128h384v128h-384z" />
    </svg>
  ),
  displayName: 'URLBlockIcon',
});

export default URLBlockIcon;
