import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ParkingLocationMirroredIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M29 1459q-29 64-29 133v72q0 76 41 139t110 94q31 69 94 110t139 41q69 0 128-34t94-94h708q35 60 94 94t128 34q69 0 128-34t94-94h162q27 0 50-10t40-27 28-41 10-50v-256q0-80-30-150t-82-122-122-82-150-30h-37l-328-328q-27-27-62-41t-74-15H256v128h29L29 1459zm739-563v256H309l99-219q8-17 24-27t35-10h301zm395 0q26 0 45 19l237 237H896V896h267zm373 1024q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10zm0-384q-53 0-99 20t-82 55-55 81-20 100H896v-512h768q53 0 99 20t82 55 55 81 20 100v256h-128q0-53-20-99t-55-82-81-55-100-20zM384 1920q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10zm-256-328q0-41 17-80l106-232h517v512H640q0-53-20-99t-55-82-81-55-100-20q-42 0-81 13t-71 37-56 57-37 74q-11-27-11-53v-72z" />
    </svg>
  ),
  displayName: 'ParkingLocationMirroredIcon',
});

export default ParkingLocationMirroredIcon;
