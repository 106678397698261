import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const FunctionalManagerDashboardIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1152 512H896V256h128v128h128v128zm512 1032q42 11 77 35t60 57 40 73 15 83q0 53-20 99t-55 82-81 55-100 20q-53 0-99-20t-82-55-55-81-20-100q0-43 14-83t39-73 61-57 78-35v-264h-512v264q42 11 77 35t60 57 40 73 15 83q0 53-20 99t-55 82-81 55-100 20q-53 0-99-20t-82-55-55-81-20-100q0-43 14-83t39-73 61-57 78-35v-264H384v264q42 11 77 35t60 57 40 73 15 83q0 53-20 99t-55 82-81 55-100 20q-53 0-99-20t-82-55-55-81-20-100q0-43 14-83t39-73 61-57 78-35v-392h640V891q-83-11-153-50t-122-99-80-135-29-159q0-93 35-174t96-143 142-96T960 0q93 0 174 35t143 96 96 142 35 175q0 83-29 158t-80 135-121 99-154 51v261h640v392zM640 448q0 66 25 124t68 102 102 69 125 25q66 0 124-25t101-68 69-102 26-125q0-66-25-124t-69-101-102-69-124-26q-66 0-124 25t-102 69-69 102-25 124zM448 1792q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50q0 27 10 50t27 40 41 28 50 10q27 0 50-10t40-27 28-41 10-50zm640 0q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50q0 27 10 50t27 40 41 28 50 10q27 0 50-10t40-27 28-41 10-50zm512 128q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50q0 27 10 50t27 40 41 28 50 10z" />
    </svg>
  ),
  displayName: 'FunctionalManagerDashboardIcon',
});

export default FunctionalManagerDashboardIcon;
