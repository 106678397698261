import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const TFVCLogoIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1760 576q-42 0-82-12t-74-34-61-53-46-70l-349 100q2 17 3 34t1 35q0 99-32 191t-94 169l453 452q34-22 73-33t80-11q60 0 112 22t92 62 61 91 23 113q0 60-22 112t-62 92-91 61-113 23q-60 0-112-22t-92-62-61-91-23-113q0-41 11-80t33-73l-452-453q-77 61-169 93t-191 33q-31 0-61-3l-90 358q35 19 63 46t47 60 30 70 11 77q0 60-22 112t-62 92-91 61-113 23q-60 0-112-22t-92-62-61-91-23-113q0-64 24-117t65-91 96-59 117-21l88-351q-87-30-159-84T108 911 28 754 0 576q0-119 45-224t124-183T352 46 576 0q91 0 175 27t156 78 126 120 85 157l354-101q1-58 24-109t63-90 90-60 111-22q60 0 112 22t92 62 61 91 23 113q0 60-22 112t-62 92-91 61-113 23zM576 896q66 0 124-25t101-68 69-102 26-125q0-66-25-124t-69-101-102-69-124-26q-66 0-124 25t-102 69-69 102-25 124q0 66 25 124t68 102 102 69 125 25z" />
    </svg>
  ),
  displayName: 'TFVCLogoIcon',
});

export default TFVCLogoIcon;
