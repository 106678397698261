import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const LargeGridIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M128 128h1664v1664H128V128zm1536 128H256v640h1408V256zM256 1024v640h640v-640H256zm768 640h640v-640h-640v640z" />
    </svg>
  ),
  displayName: 'LargeGridIcon',
});

export default LargeGridIcon;
