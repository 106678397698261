import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const PeopleIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1636 693q65 33 117 81t90 108 57 128 20 142h-128q0-79-30-149t-83-122-122-82-149-31q-79 0-149 30t-122 83-82 122-31 149q0 91-41 173t-115 136q65 33 117 81t90 108 57 128 20 142h-128q0-79-30-149t-83-122-122-82-149-31q-79 0-149 30t-122 83-82 122-31 149H128q0-73 20-141t57-128 89-108 118-82q-73-54-114-136t-42-173q0-79 30-149t83-122 122-82 149-31q91 0 173 41t136 115q38-75 97-134t134-97q-73-54-114-136t-42-173q0-79 30-149t83-122 122-82 149-31q79 0 149 30t122 83 82 122 31 149q0 91-41 173t-115 136zm-996 715q52 0 99-20t81-55 55-81 21-100q0-52-20-99t-55-81-82-55-99-21q-53 0-99 20t-81 55-55 82-21 99q0 53 20 99t55 81 81 55 100 21zm512-1024q0 53 20 99t55 81 81 55 100 21q52 0 99-20t81-55 55-81 21-100q0-52-20-99t-55-81-82-55-99-21q-53 0-99 20t-81 55-55 82-21 99z" />
    </svg>
  ),
  displayName: 'PeopleIcon',
});

export default PeopleIcon;
