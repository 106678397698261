import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const D365CustomerInsightsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1852 767q89 108 140 239t52 272q0 105-27 203t-78 183-120 155-155 120-183 77-203 28q-65 0-129-9t-127-29q-62 20-126 29t-129 9q-105 0-203-27t-183-78-155-120-121-155-77-183-28-203q0-94 23-184t65-170 104-150 140-123q19-141 86-260t169-206T816 49t270-49q107 0 205 27t184 76 154 120 119 155 77 183 27 206zm-1405 0q0 84 24 165t66 154q39-139 113-245t193-190q42-29 86-57t93-45q59-21 126-29t130-9q121 0 228 35t206 105q-20-112-77-207t-142-166-189-110-218-40q-105 0-202 32t-177 92-140 141-94 182q-16 48-21 95t-5 97zm958 511q0-95-27-184t-77-166-121-138-158-100q-83 34-150 89T756 904t-78 152-39 170q44 44 96 77t109 56 119 34 123 12q86 0 165-25t154-64v-38zm-562 625q18 0 42-1t48-5 48-8 41-11q18-7 36-17t36-22q96-59 168-147t105-197q-69 20-139 35t-142 16q-120 0-229-36t-206-105q-39-28-74-58t-66-69q-80-101-131-215t-61-245q-45 45-80 98t-60 113-38 122-13 127q0 134 49 250t136 203 203 135 251 50q21 0 38-2t38-11zm435 13q134 0 250-49t203-136 135-202 50-251q0-86-24-165t-65-155q-42-76-100-135t-129-101-152-62-168-21q-21 0-39 1t-38 11q164 109 248 270t84 357q0 28-5 57t-8 57q-8 75-35 148t-70 140-98 124-116 99q20 10 38 11t39 2z" />
    </svg>
  ),
  displayName: 'D365CustomerInsightsIcon',
});

export default D365CustomerInsightsIcon;
