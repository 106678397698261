import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const IRMReplyIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M960 1792q114 0 227-32l101 101q-79 29-161 44t-167 15q-132 0-255-34t-229-97-194-150-150-194-97-229T0 960q0-132 34-255t97-229 150-194 194-150 229-97T960 0q132 0 255 34t229 97 194 150 150 194 97 230 35 255q0 96-19 189t-56 182l-99-100q46-133 46-271 0-115-30-221t-84-198-130-169-168-130-199-84-221-30q-115 0-221 30t-198 84-169 130-130 168-84 199-30 221q0 115 30 221t84 198 130 169 168 130 199 84 221 30zM384 896h1152v128H384V896zm1408 640q53 0 99 20t82 55 55 81 20 100q0 53-20 99t-55 82-81 55-100 20v-128q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10h-293l162 163-90 90-317-317 317-317 90 90-162 163h293z" />
    </svg>
  ),
  displayName: 'IRMReplyIcon',
});

export default IRMReplyIcon;
