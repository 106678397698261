import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const RecentIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1024 2048q-142 0-272-36t-245-103-207-160-160-208-103-245-37-272q0-141 36-272t103-245 160-207 208-160T752 37t272-37q141 0 272 36t245 103 207 160 160 208 103 245 37 272q0 142-36 272t-103 245-160 207-208 160-245 103-272 37zm0-1920q-123 0-237 32t-214 90-182 141-140 181-91 214-32 238q0 123 32 237t90 214 141 182 181 140 214 91 238 32q123 0 237-32t214-90 182-141 140-181 91-214 32-238q0-123-32-237t-90-214-141-182-181-140-214-91-238-32zm0 896V384H896v768h512v-128h-384z" />
    </svg>
  ),
  displayName: 'RecentIcon',
});

export default RecentIcon;
