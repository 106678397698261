import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const RocketIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2048 0v64q0 182-32 340t-99 299-166 268-234 249l-191 572h-302v-227q-138 81-279 156l-418-418q75-141 156-279H256V722l572-190q121-135 248-235t269-166 299-98 340-33h64zM558 896q29-46 58-91t62-89l-294 98v82h174zm211 666q51-29 102-57t102-58l-372-372q-29 51-57 102t-58 103l283 282zm563-192q-44 32-89 61t-91 59v174h82l98-294zm183-327q99-99 172-201t124-214 76-235 32-264q-140 5-263 31t-235 77-215 123-203 172q-99 97-181 204T668 962l418 418q118-72 225-154t204-183zm-235-19q-53 0-99-20t-82-55-55-81-20-100q0-53 20-99t55-82 81-55 100-20q53 0 99 20t82 55 55 81 20 100q0 53-20 99t-55 82-81 55-100 20zm0-384q-27 0-50 10t-40 27-28 41-10 50q0 27 10 50t27 40 41 28 50 10q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10zM256 1536q53 0 99 20t82 55 55 81 20 100q0 53-20 99t-55 82-81 55-100 20H0v-256q0-53 20-99t55-82 81-55 100-20zm0 384q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50v128h128z" />
    </svg>
  ),
  displayName: 'RocketIcon',
});

export default RocketIcon;
