import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const EmojiIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1024 0q141 0 272 36t245 103 207 160 160 208 103 245 37 272q0 141-36 272t-103 245-160 207-208 160-245 103-272 37q-141 0-272-36t-245-103-207-160-160-208-103-244-37-273q0-141 36-272t103-245 160-207 208-160T751 37t273-37zm0 1920q123 0 237-32t214-90 182-141 140-181 91-214 32-238q0-123-32-237t-90-214-141-182-181-140-214-91-238-32q-123 0-237 32t-214 90-182 141-140 181-91 214-32 238q0 123 32 237t90 214 141 182 181 140 214 91 238 32zM640 512q27 0 50 10t40 27 28 41 10 50H512q0-27 10-50t27-40 41-28 50-10zm768 0q27 0 50 10t40 27 28 41 10 50h-256q0-27 10-50t27-40 41-28 50-10zm64 256q40 0 75 15t61 41 41 61 15 75v64q0 133-50 249t-137 204-203 137-250 50q-133 0-249-50t-204-137-137-203-50-250q0-48 8-94t29-82 58-58 97-22q74 0 129 20t104 44 100 44 115 20q64 0 114-20t100-44 105-44 129-20zm64 192q0-26-19-45t-45-19q-43 0-79 9t-69 24-66 31-68 31-76 23-90 10q-49 0-89-9t-76-24-68-31-66-31-70-23-79-10q-26 0-45 19t-19 45v64q0 106 40 199t110 162 163 110 199 41q106 0 199-40t162-110 110-163 41-199v-64z" />
    </svg>
  ),
  displayName: 'EmojiIcon',
});

export default EmojiIcon;
