import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const MSNLogoIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1260 698q0 149-23 293t-71 288q-23 71-44 140t-46 134-55 127-70 116-93 104-124 91q-45 26-94 41t-101 16q-56 0-97-18t-68-52-40-77-14-98q0-67 26-139t68-139 96-123 110-91q7-4 13-6t14-3q5 0 22 6t39 17 49 23 49 24 43 21 28 14q14 6 27 9t28 9l4-8-52-40q-26-20-51-42-136-117-222-257T489 762q-13-65-22-130t-10-131v-27q0-13 2-28 3-47 14-98t30-101 46-94 65-79 83-54T799 0q45 0 88 13t82 37q72 42 125 110t90 148 54 168 21 166q1 14 1 28t0 28zm503-112q22 0 36 10t21 25 11 34 3 37q0 81-32 168t-80 173-105 162-105 138q-35 44-76 78t-95 53l3 7h90q-10 68-22 128t-34 117-53 109-82 104q-9 9-22 20t-27 20-30 16-29 7q-22 0-36-12t-21-31-11-40-3-38q0-35 14-92t38-125 51-142 57-142 52-127 40-94q45-106 97-208t126-191q29-34 62-65t68-61q17-14 44-26t50-12z" />
    </svg>
  ),
  displayName: 'MSNLogoIcon',
});

export default MSNLogoIcon;
