import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SelectAllIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M0 128V0h128v128H0zm0 256V256h128v128H0zm0 256V512h128v128H0zm0 256V768h128v128H0zm0 256v-128h128v128H0zm0 256v-128h128v128H0zm0 256v-128h128v128H0zm0 256v-128h128v128H0zM1792 384V256h128v128h-128zm0 256V512h128v128h-128zm0 256V768h128v128h-128zm0 256v-128h128v128h-128zm0 256v-128h128v128h-128zm0 256v-128h128v128h-128zm0 256v-128h128v128h-128zM384 0v128H256V0h128zm256 0v128H512V0h128zm256 0v128H768V0h128zm256 0v128h-128V0h128zm256 0v128h-128V0h128zm128 128V0h128v128h-128zM256 1920v-128h128v128H256zm256 0v-128h128v128H512zm256 0v-128h128v128H768zm256 0v-128h128v128h-128zm256 0v-128h128v128h-128zm256 0v-128h128v128h-128zM1792 0h128v128h-128V0zm-256 384v1152H384V384h1152zm-128 512V512h-384v384h384zM896 512H512v384h384V512zm-384 512v384h384v-384H512zm512 384h384v-384h-384v384z" />
    </svg>
  ),
  displayName: 'SelectAllIcon',
});

export default SelectAllIcon;
