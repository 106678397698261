import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const BuildDefinitionIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M896 1920h128v128H0v-128h128v-832q0-76 28-143t76-119 114-84 142-37l608-627q35-36 82-57t98-21q36 0 72 10t68 29 57 46 41 61l384 858q17 37 17 83 0 40-14 75t-40 61-61 42-75 15q-57 0-104-31t-71-83l-251-562-408 421q2 16 3 31t2 32v832zm498-1414l198 440 117-50-220-493q-19 29-45 54t-50 49zm394 581q0-20-9-38t-17-36l-118 50q7 15 13 30t15 29 21 21 33 9q27 0 44-19t18-46zM644 727q62 23 113 65t85 99l530-546q18-19 27-42t9-49q0-26-10-49t-27-40-40-27-49-10q-56 0-94 39L644 727zM256 1920h512v-832q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100v832zm256-896q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10zm0 128h64-64zm896 640v-128h640v128h-640zm0-384h640v128h-640v-128zm-256 128v-128h128v128h-128zm256 512v-128h640v128h-640zm-256 0v-128h128v128h-128zm0-256v-128h128v128h-128z" />
    </svg>
  ),
  displayName: 'BuildDefinitionIcon',
});

export default BuildDefinitionIcon;
