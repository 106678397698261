import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const TimelineDeliveryIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1280 1024H897V896h383v128zM896 768H256V640h640v128zm1152-512v704q0-30-12-61t-30-60-41-55-44-45V384h-513v384h-128V384H768v128H640V384H128v1153h512V896h128v768H0V256h2048zm-198 944q46 26 82 62t62 79 40 93 14 102h-128q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100q0 49-18 95t-52 81q46 26 82 62t62 79 40 93 14 102h-128q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100H768q0-52 14-101t39-93 62-80 83-62q-33-35-51-81t-19-95q0-53 20-99t55-82 81-55 100-20q49 0 95 18t81 52q54-96 150-150-33-35-51-81t-19-95q0-53 20-99t55-82 81-55 100-20q53 0 99 20t82 55 55 81 20 100q0 49-18 95t-52 81zm-698 464q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50q0 27 10 50t27 40 41 28 50 10zm384-640q0 27 10 50t27 40 41 28 50 10q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50z" />
    </svg>
  ),
  displayName: 'TimelineDeliveryIcon',
});

export default TimelineDeliveryIcon;
