import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const FocalPointIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1344 768q-26 0-45-19t-19-45q0-26 19-45t45-19q26 0 45 19t19 45q0 26-19 45t-45 19zm595 1133l-263-262q-43 42-87 79t-95 69l151 152-90 90-182-179q-167 70-349 70-105 0-205-23t-192-69-170-110-144-149l-204 204-90-90 222-223q-57-99-85-210t-28-226q0-124 32-238t90-214 140-181 181-140 214-91 239-32q124 0 238 32t214 90 181 140 140 181 91 214 32 239q0 139-41 270t-122 245l272 272-90 90zM256 1024q0 89 20 175t60 166l368-367 384 384 256-256 321 321q62-95 94-202t33-221q0-106-27-204t-78-183-120-156-155-120-184-77-204-28q-106 0-204 27t-183 78-156 120-120 155-77 184-28 204zm768 768q129 0 251-42l-571-572-299 300q54 74 123 132t148 98 168 62 180 22zm376-99q52-29 98-65t87-80l-241-242-166 166 222 221z" />
    </svg>
  ),
  displayName: 'FocalPointIcon',
});

export default FocalPointIcon;
