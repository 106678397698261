import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ATPLogoIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M960 0q53 0 98 6t89 20 84 34 86 48q74 46 145 75t145 45 150 22 163 6v352q0 157-30 308t-89 292-143 266-190 233-235 194-273 147q-145-60-273-146t-234-194-191-234-142-266-89-291T0 608V256q87 0 164-6t149-22 144-45 147-76q43-27 85-47t84-33 89-20 98-7zm832 382q-152-7-286-48t-263-122q-68-43-134-63t-149-21q-82 0-149 21t-135 64q-128 80-262 121t-286 48v226q0 210 58 406t167 366 262 305 345 223q191-88 345-223t262-304 166-366 59-407V382zM960 256q62 0 110 14t101 48q115 73 236 117t257 64v109q0 182-49 354t-141 323-222 274-292 207q-162-84-292-207t-222-273-141-323-49-355V499q136-19 256-62t235-118q51-32 101-47t112-16zm96 640l128-256H960l-224 448h192l-192 384 576-576h-256z" />
    </svg>
  ),
  displayName: 'ATPLogoIcon',
});

export default ATPLogoIcon;
