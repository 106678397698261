import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ReminderGroupIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2029 1453l-557 558-269-270 90-90 179 178 467-466 90 90zm-1293 9q66 33 119 81t90 107 58 128 21 142H896q0-79-30-149t-82-122-123-83-149-30q-80 0-149 30t-122 82-83 123-30 149H0q0-73 20-142t58-128 91-107 119-81q-75-54-117-135t-43-175q0-79 30-149t82-122 122-83 150-30q94 0 175 42t135 118q38-76 98-136t136-98q-75-54-117-135t-43-175q0-79 30-149t82-122 122-83 150-30q79 0 149 30t122 82 83 123 30 149q0 94-42 175t-118 135q66 33 119 81t90 107 58 128 21 142h-128q0-79-30-149t-82-122-123-83-149-30q-80 0-149 30t-122 82-83 123-30 149q0 94-42 175t-118 135zm288-1078q0 53 20 99t55 82 81 55 100 20q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100zm-768 768q0 53 20 99t55 82 81 55 100 20q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100z" />
    </svg>
  ),
  displayName: 'ReminderGroupIcon',
});

export default ReminderGroupIcon;
