import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SearchCalendarIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2048 128v768q-58-57-128-95V640H128v1152h707l-30 30q-13 13-23 28t-20 30-16 40H0V128h384V0h128v128h1024V0h128v128h384zm-128 384V256h-256v128h-128V256H512v128H384V256H128v256h1792zm-256 384q79 0 149 30t122 82 82 123 30 149q0 80-30 149t-82 122-122 83-149 30q-60 0-116-18t-106-54l-437 437q-19 19-45 19t-45-19-19-45q0-26 19-45l437-437q-35-49-53-105t-19-117q0-79 30-149t82-122 122-83 150-30zm0 640q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100q0 53 20 99t55 82 81 55 100 20z" />
    </svg>
  ),
  displayName: 'SearchCalendarIcon',
});

export default SearchCalendarIcon;
