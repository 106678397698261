import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ProductionFloorManagementIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M640 768H256V384h384v384zm512 0H768V384h384v384zm-128-256H896v128h128V512zm640 256h-384V384h384v384zm-128-256h-128v128h128V512zm-128 768q0-27 10-50t27-40 41-28 50-10q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50zm-1152 0q0-27 10-50t27-40 41-28 50-10q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50zm384 0q0-27 10-50t27-40 41-28 50-10q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50zm640 0q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10q27 0 50 10t40 27 28 41 10 50zm-1152 0q0 53 20 99t55 82 81 55 100 20h640v128H384q-79 0-149-30t-122-83-82-122-31-149q0-79 30-149t83-122 122-82 149-31h1152q71 0 135 25t114 68 84 103 45 130l-135 135q6-19 9-38t4-39q0-53-20-99t-55-82-81-55-100-20H384q-53 0-99 20t-82 55-55 81-20 100zm1901 173l-557 558-269-270 90-90 179 178 467-466 90 90z" />
    </svg>
  ),
  displayName: 'ProductionFloorManagementIcon',
});

export default ProductionFloorManagementIcon;
