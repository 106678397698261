import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AdminYLogoInverse32Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1406 1025q0 26-10 49t-27 40-40 27-49 10V898q26 0 49 10t40 27 27 41 10 49zM64 273L1216 68v1912L64 1781V273zm257 387l206 508v284l159 14v-298l248-560-184 16-119 321q-6 16-9 32t-7 33h-2l-6-34q-3-17-10-33L487 646l-166 14zm1695 449l-62 239-210-28q-17 26-35 50t-40 46l73 189-213 125-127-166q-60 13-122 15v-209q71 0 134-27t110-73 74-110 27-134q0-71-27-134t-74-110-110-74-134-27V467h5l79-178 238 63-27 203q53 34 99 80l186-71 124 213-166 127q6 28 9 56t4 57v10l185 82z" />
    </svg>
  ),
  displayName: 'AdminYLogoInverse32Icon',
});

export default AdminYLogoInverse32Icon;
