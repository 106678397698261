import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const CRMServicesIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1185 1179q-88-75-195-115t-222-40q-88 0-170 23t-153 64-129 100-100 130-65 153-23 170H0q0-120 35-231t101-205 156-167 204-115q-113-74-176-186t-64-248q0-106 40-199t109-163T568 40 768 0q106 0 199 40t163 109 110 163 40 200q0 66-16 129t-48 119-75 103-101 83q65 25 124 61t111 81l-90 91zM384 512q0 80 30 149t82 122 122 83 150 30q79 0 149-30t122-82 83-122 30-150q0-79-30-149t-82-122-123-83-149-30q-80 0-149 30t-122 82-83 123-30 149zm1344 256q66 0 124 25t101 69 69 102 26 124q0 66-25 124t-69 102-102 69-124 25q-23 0-45-3l-587 587q-27 27-62 41t-74 15q-40 0-75-15t-61-41-41-61-15-75q0-38 14-73t42-63l587-587q-3-22-3-45 0-66 25-124t68-101 102-69 125-26zm0 512q40 0 75-15t61-41 41-61 15-75q0-41-19-82l-146 146h-91v-91l146-146q-41-19-82-19-40 0-75 15t-61 41-41 61-15 75q0 41 19 82l-640 641q-19 19-19 45t19 45 45 19q26 0 45-19l641-640q41 19 82 19z" />
    </svg>
  ),
  displayName: 'CRMServicesIcon',
});

export default CRMServicesIcon;
