import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ConnectContactsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1632 1462q66 33 119 81t90 107 58 128 21 142h-128q0-79-30-149t-82-122-123-83-149-30q-80 0-149 30t-122 82-83 123-30 149H896q0-73 20-142t58-128 91-107 119-81q-75-54-117-135t-43-175q0-79-30-149t-82-122-123-83-149-30q-80 0-149 30t-122 82-83 123-30 149H128q0-73 20-142t58-128 91-107 119-81q-75-54-117-135t-43-175q0-79 30-149t82-122 122-83T640 0q79 0 149 30t122 82 83 123 30 149q0 94-42 175T864 694q76 38 136 98t98 136q54-75 135-117t175-43q79 0 149 30t122 82 83 123 30 149q0 94-42 175t-118 135zM640 640q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100q0 53 20 99t55 82 81 55 100 20zm768 768q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100q0 53 20 99t55 82 81 55 100 20zm128-1152h-384V128h512v512h-128V256z" />
    </svg>
  ),
  displayName: 'ConnectContactsIcon',
});

export default ConnectContactsIcon;
