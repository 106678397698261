import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const OneDriveFolder16Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2048 256v1536H0V512h832l256-256h960zM646 1408q-7-31-7-67 0-92 53-150t141-78q29-110 105-171t192-61q63 0 118 24t99 71q15-4 30-6t30-4q1-10 1-19t0-19q0-13-4-28t-9-26q-21-64-64-111t-107-72q-51-21-108-21-78 0-142 41T873 819q-22-14-52-21t-55-7q-47 0-87 18t-71 50-47 73-17 88v11q0 6 1 12-37 3-67 18t-51 41-32 58-11 69q0 35 14 67t39 57 57 40 68 15h84zm964 128q28 0 52-12t43-33 28-47 10-54q0-28-8-54t-25-46-39-32-53-15v-14q0-42-13-78t-37-64-60-42-80-16q-24 0-52 6t-50 18q-34-51-83-79t-112-29q-50 0-93 16t-75 46-52 71-24 92q-37 1-70 13t-59 34-40 53-15 71q0 91 49 143t142 52h716z" />
    </svg>
  ),
  displayName: 'OneDriveFolder16Icon',
});

export default OneDriveFolder16Icon;
