import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const DecimalsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M465 265q90 0 155 32t110 85 72 124 43 147 20 154 5 147q0 71-7 150t-25 157-48 149-79 126-116 86-159 33q-86 0-150-31t-109-82-75-120-46-142-23-150-7-142q0-73 6-155t23-162 47-154 80-130 118-89 165-33zm-16 1285q67 0 115-29t82-78 53-111 30-127 14-126 3-111q0-48-2-110t-12-129-28-131-51-115-80-82-116-31q-71 0-121 31t-84 81-54 116-31 132-14 132-3 116q0 50 3 111t13 125 30 124 53 108 82 75 118 29zm574-116q44 0 73 29t30 74q0 21-8 39t-22 32-33 22-40 8q-44 0-72-28t-29-73q0-44 28-73t73-30zm594-1169q90 0 155 32t110 85 72 124 43 147 20 154 5 147q0 71-7 150t-25 157-48 149-79 126-116 86-159 33q-86 0-150-31t-109-82-75-120-46-142-23-150-7-142q0-73 6-155t23-162 47-154 80-130 118-89 165-33zm-16 1285q67 0 115-29t82-78 53-111 30-127 14-126 3-111q0-48-2-110t-12-129-28-131-51-115-80-82-116-31q-71 0-121 31t-84 81-54 116-31 132-14 132-3 116q0 50 3 111t13 125 30 124 53 108 82 75 118 29z" />
    </svg>
  ),
  displayName: 'DecimalsIcon',
});

export default DecimalsIcon;
