import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const PowerAppsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1760 256q60 0 112 22t92 62 61 91 23 113v768q0 55-19 104t-54 87-82 63-101 31v-128q28-6 51-20t41-36 26-46 10-55V544q0-33-12-62t-35-51-51-34-62-13H288q-33 0-62 12t-51 35-34 51-13 62v768q0 29 9 54t27 47 40 35 52 21v128q-54-6-101-30t-81-63-54-88-20-104V544q0-60 22-112t62-92 91-61 113-23h1472zm-253 1332q0 26-19 45l-137 137q-19 19-46 19t-46-19l-137-137q-19-19-19-45 0-27 19-46l137-137q19-19 46-19t46 19l137 137q19 19 19 46zm285-286q0 26-19 45l-137 137q-19 19-46 19-26 0-45-19l-137-137q-19-19-19-45 0-27 19-46l137-138q19-19 45-19 27 0 46 19l137 138q19 19 19 46zM734 1789q-26 0-45-19l-379-378q-9-9-20-19t-20-21-16-23-7-27q0-15 6-27t16-24 21-21 20-19l379-378q19-19 45-19 27 0 46 19l378 378q9 9 20 19t21 21 16 23 7 28q0 15-6 27t-16 23-21 21-21 19l-378 378q-19 19-46 19zm202-487q0-27-19-46l-137-138q-19-19-46-19-26 0-45 19l-138 138q-19 19-19 46t18 45 36 35l103 102q19 19 45 19 27 0 46-19l102-102q18-18 36-35t18-45zm369-85q-27 0-46-19l-137-137q-19-19-19-45 0-27 19-46l137-137q19-19 46-19t46 19l137 137q19 19 19 46 0 26-19 45l-137 137q-19 19-46 19z" />
    </svg>
  ),
  displayName: 'PowerAppsIcon',
});

export default PowerAppsIcon;
