import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const FamilyIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1790 1717q98 48 162 135t81 196h-110q-11-57-41-106t-73-84-97-56-112-20q-59 0-112 20t-97 55-73 85-41 106h-110q16-108 80-195t163-136q-57-45-88-109t-32-136q0-45 12-87t36-79 57-66 74-49q-27-39-62-69t-76-53-86-33-93-12q-80 0-153 31t-127 91q24 65 24 134 0 92-41 173t-115 136q65 33 117 81t90 108 57 128 20 142H896q0-79-30-149t-82-122-123-83-149-30q-80 0-149 30t-122 82-83 123-30 149H0q0-73 20-141t57-128 89-108 118-82q-74-55-115-136t-41-173q0-79 30-149t82-122 122-83 150-30q85 0 161 36t132 100q26-25 56-45t63-38q-74-55-115-136t-41-173q0-79 30-149t82-122 122-83 150-30q79 0 149 30t122 82 83 123 30 149q0 92-41 173t-115 136q70 37 126 90t95 123q64 0 120 24t99 67 66 98 24 121q0 72-31 136t-89 109zM512 1536q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100q0 53 20 99t55 82 81 55 100 20zm384-896q0 53 20 99t55 82 81 55 100 20q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100zm704 630q-42 0-78 16t-64 43-44 64-16 79q0 42 16 78t43 64 64 44 79 16q42 0 78-16t64-43 44-64 16-79q0-42-16-78t-43-64-64-44-79-16z" />
    </svg>
  ),
  displayName: 'FamilyIcon',
});

export default FamilyIcon;
