import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AnalyticsReportIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M128 1024q0 149 46 285t131 250 203 197 260 126v132q-169-44-310-138t-243-226-158-291-57-335q0-141 36-272t104-244 160-207 207-161T752 37t272-37q135 0 260 33t236 95 203 149 161 193 110 230 51 257l-154-154q-38-148-120-272t-198-214-255-139-294-50q-124 0-238 32t-213 90-182 141-140 181-91 214-32 238zm896-512q-106 0-199 40T663 662 553 825t-41 199q0 103 38 196t112 166l-91 91q-91-91-139-208t-48-245q0-88 23-170t64-153 100-129 130-100 153-65 170-23q75 0 147 17t137 51 123 80 102 108h-174q-70-62-155-95t-180-33zm603 256l421 421v859H896V768h731zm37 219v165h165l-165-165zm256 933v-640h-384V896h-512v1024h896zm-128-512v384h-128v-384h128zm-640 384v-512h128v512h-128zm256 0v-256h128v256h-128z" />
    </svg>
  ),
  displayName: 'AnalyticsReportIcon',
});

export default AnalyticsReportIcon;
