import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const StaffNotebookLogoInverted16Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M0 1824V224L1152 0v2048L0 1824zM177 540v968l201 11v-314q0-156-1-314l366 648 232 13V495l-225 13v639l-3-6q-2-4-14-23t-32-57-61-106-99-172-146-255l-218 12zm1775 228q16 0 33 5t31 15 23 24 9 33v230q0 19-9 33t-22 24-31 15-34 5h-32V768h32zm0 512q16 0 33 5t31 15 23 24 9 33v230q0 19-9 33t-22 24-31 15-34 5h-32v-384h32zm96-947v230q0 19-9 33t-22 24-31 15-34 5h-160v1152q0 26-10 49t-27 41-41 28-50 10h-384v-384h256v-128h-256v-128h256v-128h-256V960q0 19 15 31t36 19 42 11 35 3q13 0 34-3t43-10 36-20 15-31V847l-128 64-128-64V720l128 64 288-144-288-144-128 64V128h384q26 0 49 10t41 27 28 41 10 50h160q16 0 33 5t31 15 23 24 9 33z" />
    </svg>
  ),
  displayName: 'StaffNotebookLogoInverted16Icon',
});

export default StaffNotebookLogoInverted16Icon;
