import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const PeopleAlertIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1472 896q119 0 224 45t183 124 123 183 46 224q0 119-45 224t-124 183-183 123-224 46q-119 0-224-45t-183-124-123-183-46-224q0-119 45-224t124-183 183-123 224-46zm0 1024q93 0 174-35t142-96 96-142 36-175q0-93-35-174t-96-142-142-96-175-36q-93 0-174 35t-142 96-96 142-36 175q0 93 35 174t96 142 142 96 175 36zm-64-384v-384h128v384h-128zm0 256v-128h128v128h-128zm-494-748q-66 84-102 184t-42 207q-54 48-120 74t-138 27q-80 0-149 30t-122 82-83 123-30 149H0q0-73 20-141t57-129 90-108 118-81q-74-54-115-135t-42-174q0-79 30-149t82-122 122-83 150-30q93 0 174 41t136 117q37-75 96-135t134-98q-74-55-115-136t-41-173q0-79 30-149t82-122 122-83 150-30q79 0 149 30t122 82 83 123 30 149q0 80-30 149t-82 122-123 83-149 30q-64 0-122 20t-107 56-84 88-53 112zm110-660q0 53 20 99t55 82 81 55 100 20q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100zM512 1408q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20q-53 0-99 20t-82 55-55 81-20 100q0 53 20 99t55 82 81 55 100 20z" />
    </svg>
  ),
  displayName: 'PeopleAlertIcon',
});

export default PeopleAlertIcon;
