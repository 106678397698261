import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const HideIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1024 896q27 0 50 10t40 27 28 41 10 50q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10zm576 256q93 0 174 35t143 96 96 142 35 175q0 93-35 174t-96 143-142 96-175 35q-93 0-174-35t-143-96-96-142-35-175q0-93 35-174t96-143 142-96 175-35zm-320 448q0 66 25 124t68 102 102 69 125 25q47 0 92-13t84-40l-443-443q-26 39-39 84t-14 92zm587 176q26-39 39-84t14-92q0-66-25-124t-69-101-102-69-124-26q-47 0-92 13t-84 40l443 443zM640 1024q0 74 26 139t72 117 108 84 136 42q19 2 38 2t39 0q-22 63-29 128-152 0-294-38t-273-107-247-162T0 1024q97-109 213-203t246-162 273-108 292-39q153 0 296 36t272 104 245 161 211 211l-91 91-8-9q-108-126-232-224t-280-161q48 66 73 144t26 159v6q-65 7-128 29v-35q0-79-30-149t-83-122-122-82-149-31q-79 0-149 30t-122 83-82 122-31 149zm-37 291q-45-64-68-138t-23-153q0-78 23-152t68-139q-120 52-226 126t-200 165q94 91 200 165t226 126z" />
    </svg>
  ),
  displayName: 'HideIcon',
});

export default HideIcon;
