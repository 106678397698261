import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const PowerApps2LogoIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1355 1121q31 0 53 22l159 160q22 22 22 53 0 30-22 52l-159 160q-22 22-53 22t-53-22l-159-160q-22-22-22-52 0-31 22-53l159-160q22-22 53-22zm331-331q31 0 53 22l159 159q22 22 22 53t-22 53l-159 159q-22 22-53 22t-53-22l-159-159q-22-22-22-53t22-53l159-159q22-22 53-22zm-428 234q0 17-7 31t-19 27-23 24-24 22l-439 439q-22 22-53 22t-53-22l-438-439q-11-11-24-22t-24-23-18-27-8-32q0-17 7-31t19-28 24-24 24-22l438-438q22-22 53-22t53 22l439 438q11 11 23 22t24 24 18 27 8 32zm-565 234q31 0 53-22l118-119q10-10 21-20t21-21 15-23 6-29q0-31-22-53L746 812q-22-22-53-22t-53 22L481 971q-22 22-22 53 0 16 6 28t15 24 20 21 22 20l118 119q22 22 53 22zm662-331q-31 0-53-22l-159-160q-22-22-22-52 0-31 22-53l159-159q22-22 53-22t53 22l159 159q22 22 22 53 0 30-22 52l-159 160q-22 22-53 22z" />
    </svg>
  ),
  displayName: 'PowerApps2LogoIcon',
});

export default PowerApps2LogoIcon;
