import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const PowerBILogoIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1760 256q60 0 112 22t92 62 61 91 23 113v768q0 56-20 105t-54 87-81 62-101 31v-128q27-6 50-20t41-35 27-47 10-55V544q0-33-12-62t-35-51-51-34-62-13H288q-33 0-62 12t-51 35-34 51-13 62v768q0 33 12 62t35 51 51 34 62 13h32v128h-32q-60 0-112-22t-92-62-61-91-23-113V544q0-60 22-112t62-92 91-61 113-23h1472zM576 1312q27 0 50 10t40 27 28 41 10 50v192q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50v-192q0-27 10-50t27-40 41-28 50-10zm320-384q27 0 50 10t40 27 28 41 10 50v576q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50v-576q0-27 10-50t27-40 41-28 50-10zm320 128q27 0 50 10t40 27 28 41 10 50v448q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50v-448q0-27 10-50t27-40 41-28 50-10zm320-384q27 0 50 10t40 27 28 41 10 50v832q0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50V800q0-27 10-50t27-40 41-28 50-10z" />
    </svg>
  ),
  displayName: 'PowerBILogoIcon',
});

export default PowerBILogoIcon;
