import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ServiceOffIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1044 710l-855 856q-29 29-45 67t-16 80q0 42 16 80t45 66 66 44 80 17q41 0 79-16t68-45l542-541v181l-451 451q-48 48-109 73t-129 25q-69 0-129-26t-107-73-72-106-27-130q0-69 25-129t73-109l806-806q-4-23-6-46t-2-47q0-79 20-152t58-138 91-117 117-90 137-58 153-21q54 0 99 8t88 24 83 38 86 48l-394 394 102 102 394-394q24 42 45 84t38 87 27 90 10 96q0 66-16 131t-47 124-72 111-95 92q-46-18-94-28t-98-12q65-24 119-66t92-96 60-119 21-135q0-75-24-144l-360 359-282-282 359-360q-68-24-141-24-93 0-174 35t-142 96-96 142-36 175q0 34 6 67t14 67zm556 442q93 0 174 35t143 96 96 142 35 175q0 93-35 174t-96 143-142 96-175 35q-93 0-174-35t-143-96-96-142-35-175q0-93 35-174t96-143 142-96 175-35zm-320 448q0 66 25 124t68 102 102 69 125 25q47 0 92-13t84-40l-443-443q-26 39-39 84t-14 92zm587 176q26-39 39-84t14-92q0-66-25-124t-69-101-102-69-124-26q-47 0-92 13t-84 40l443 443z" />
    </svg>
  ),
  displayName: 'ServiceOffIcon',
});

export default ServiceOffIcon;
