import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AzureAPIManagementIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1661 640q80 0 150 30t123 81 83 122 31 151q0 80-30 149t-82 122-123 83-149 30h-128v-128h128q53 0 99-20t82-55 55-81 20-100q0-53-20-99t-55-82-81-55-100-20h-128v-64q0-93-35-174t-96-142-142-96-175-36q-63 0-121 17t-109 48-93 76-72 99l-25 46q-38-13-76-21t-80-9q-80 0-149 30t-122 82-83 123-30 149q0 80 30 149t82 122 122 83 150 30h105q11-36 32-65t49-51 63-32 71-12q46 0 87 17t71 48 48 72 18 87q0 47-17 87t-48 71-72 48-87 18q-37 0-71-11t-62-33-50-50-32-66H512q-106 0-199-40t-162-110-110-163T0 896q0-106 40-199t110-162 163-110 199-41q46 0 93 9 39-61 92-110t116-83 132-53 143-19q110 0 208 39t176 108 127 162 62 203zM832 832q106 0 199 40t163 109 110 163 40 200q0 106-40 199t-109 163-163 110-200 40q-103 0-196-38t-166-112l91-91q54 55 124 84t147 29q79 0 149-30t122-83 82-122 31-149q0-79-30-149t-83-122-122-82-149-31q-77 0-147 29t-124 84l-91-91q73-73 166-111t196-39z" />
    </svg>
  ),
  displayName: 'AzureAPIManagementIcon',
});

export default AzureAPIManagementIcon;
