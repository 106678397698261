import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SkypeLogo16Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1853 1142q33 59 50 123t18 133q0 108-41 203t-112 166-167 112-204 41q-70 0-137-18t-128-54q-25 2-49 4t-50 2q-114 0-219-29t-198-83-168-129-130-168-84-197-30-220q0-30 2-60t8-61q-34-59-51-124t-18-134q0-108 41-203t112-165 167-112 203-41q73 0 143 20t133 59q23-2 44-3t45-2q114 0 219 29t198 83 168 129 130 168 84 197 30 220q0 29-2 57t-7 57zm-467 96q0-61-20-107t-53-81-76-61-86-46-87-36-75-33-54-35-20-42q0-24 12-39t31-23 41-11 41-3q33 0 62 8t54 18 49 18 44 8q47 0 74-32t28-77q0-34-16-59t-41-42-59-27-67-16-66-8-58-2q-66 0-135 16t-124 52-91 91-36 133q0 81 35 136t87 94 113 64 114 48 87 47 35 58q0 25-10 40t-28 24-37 11-41 3q-42 0-73-10t-59-24-53-23-57-11q-48 0-79 29t-32 78q0 37 18 64t48 47 67 31 76 18 75 9 64 3q73 0 141-15t120-51 85-93 32-141z" />
    </svg>
  ),
  displayName: 'SkypeLogo16Icon',
});

export default SkypeLogo16Icon;
