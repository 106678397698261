import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const OfficeFormsLogoIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1344 1216h448v448h-448v-448zm128 320h192v-192h-192v192zm96-640q-60 0-112-22t-92-62-61-91-23-113q0-60 22-112t62-92 91-61 113-23q60 0 112 22t92 62 61 91 23 113q0 60-22 112t-62 92-91 61-113 23zm0-448q-33 0-62 12t-51 35-34 51-13 62q0 33 12 62t35 51 51 34 62 13q33 0 62-12t51-35 34-51 13-62q0-33-12-62t-35-51-51-34-62-13zm395-320q35 0 60 25t25 60v1622q0 35-25 60t-60 25H597q-35 0-60-25t-25-60v-299H85q-35 0-60-25t-25-60V597q0-35 25-60t60-25h427V213q0-35 25-60t60-25h1366zM640 512h299q35 0 60 25t25 60v363h896V256H640v256zm80 590V966H461V824h278V688H304v672h157v-258h259zm1200 690v-704h-896v363q0 35-25 60t-60 25H640v256h1280z" />
    </svg>
  ),
  displayName: 'OfficeFormsLogoIcon',
});

export default OfficeFormsLogoIcon;
