import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const UneditableIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1472 1152q93 0 174 35t143 96 96 142 35 175q0 93-35 174t-96 143-142 96-175 35q-93 0-174-35t-143-96-96-142-35-175q0-93 35-174t96-143 142-96 175-35zm-320 448q0 66 25 124t68 102 102 69 125 25q47 0 92-13t84-40l-443-443q-26 39-39 84t-14 92zm587 176q26-39 39-84t14-92q0-66-25-124t-69-101-102-69-124-26q-47 0-92 13t-84 40l443 443zM1712 0q70 0 131 26t106 72 72 107 27 131q0 66-25 127t-73 110l-451 451h-182l384-384-293-293L326 1428q52 17 98 45t85 66 66 84 45 99l276-277v182l-263 263L0 2048l158-633 6-6q3-3 7-6L1475 98q48-48 109-73t128-25zM505 1790q-10-46-32-87t-55-73-73-54-87-33l-82 329 329-82zM1792 549q25-25 48-47t41-46 28-53 11-67q0-43-16-81t-45-66-66-44-81-17q-38 0-66 10t-53 29-47 41-47 48l293 293z" />
    </svg>
  ),
  displayName: 'UneditableIcon',
});

export default UneditableIcon;
