import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ColorSolidIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1024 0q141 0 272 36t244 104 207 160 161 207 103 245 37 272q0 141-36 272t-104 244-160 207-207 161-245 103-272 37q-53 0-99-20t-82-55-55-81-20-100q0-53 13-103t42-96q6-11 11-17t12-16q25-39 37-71t13-81q0-53-20-99t-55-82-81-55-100-20q-48 0-80 12t-72 38q-10 7-16 12t-17 11q-45 28-95 41t-104 14q-53 0-99-20t-82-55-55-81-20-100q0-141 36-272t104-244 160-207 207-161T752 37t272-37zm384 480q-33 0-62 12t-51 35-34 51-13 62q0 33 12 62t35 51 51 34 62 13q33 0 62-12t51-35 34-51 13-62q0-33-12-62t-35-51-51-34-62-13zM512 928q33 0 62-12t51-35 34-51 13-62q0-33-12-62t-35-51-51-34-62-13q-33 0-62 12t-51 35-34 51-13 62q0 33 12 62t35 51 51 34 62 13zm384-256q33 0 62-12t51-35 34-51 13-62q0-33-12-62t-35-51-51-34-62-13q-33 0-62 12t-51 35-34 51-13 62q0 33 12 62t35 51 51 34 62 13zm384 1024q33 0 62-12t51-35 34-51 13-62q0-33-12-62t-35-51-51-34-62-13q-33 0-62 12t-51 35-34 51-13 62q0 33 12 62t35 51 51 34 62 13zm256-384q33 0 62-12t51-35 34-51 13-62q0-33-12-62t-35-51-51-34-62-13q-33 0-62 12t-51 35-34 51-13 62q0 33 12 62t35 51 51 34 62 13z" />
    </svg>
  ),
  displayName: 'ColorSolidIcon',
});

export default ColorSolidIcon;
