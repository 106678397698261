import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AddLinkIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M768 1216q0 66 25 124t68 102 102 69 125 25h192v128h-192q-93 0-174-35t-142-96-96-142-36-175q0-93 35-174t96-142 142-96 175-36h64v128h-64q-66 0-124 25t-102 69-69 102-25 124zm768-320V768h64q93 0 174 35t142 96 96 142 36 175q0 88-33 169t-95 144v-313q0-66-25-124t-69-101-102-69-124-26h-64zM960 384q93 0 174 35t142 96 96 142 36 175q0 93-35 174t-96 142-142 96-175 36h-64v-128h64q66 0 124-25t101-68 69-102 26-125q0-66-25-124t-69-101-102-69-124-26H448q-66 0-124 25t-102 69-69 102-25 124q0 66 25 124t68 102 102 69 125 25h64v128h-64q-93 0-174-35t-142-96-96-142T0 832q0-93 35-174t96-142 142-96 175-36h512zm1088 1280v128h-256v256h-128v-256h-256v-128h256v-256h128v256h256z" />
    </svg>
  ),
  displayName: 'AddLinkIcon',
});

export default AddLinkIcon;
