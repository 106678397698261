import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const FlowIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1408 256h640v640h-640V640h-120l-449 896H640v256H0v-640h640v256h120l449-896h199V256zM512 1664v-384H128v384h384zm1408-896V384h-384v384h384z" />
    </svg>
  ),
  displayName: 'FlowIcon',
});

export default FlowIcon;
