import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SunQuestionMarkIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M960 512q93 0 174 35t143 96 96 142 35 175q0 93-35 174t-96 143-142 96-175 35q-93 0-174-35t-143-96-96-142-35-175q0-93 35-174t96-143 142-96 175-35zm0 768q66 0 124-25t101-68 69-102 26-125q0-66-25-124t-69-101-102-69-124-26q-66 0-124 25t-102 69-69 102-25 124q0 66 25 124t68 102 102 69 125 25zm64-896H896V0h128v384zM896 1536h128v384H896v-384zm-512-512H0V896h384v128zm123-426L236 326l90-90 272 271-91 91zm1177-272l-271 272-91-91 272-271 90 90zM507 1322l91 91-272 271-90-90 271-272zm1157 598h128v128h-128v-128zm64-768q53 0 99 20t82 55 55 81 20 100q0 46-14 81t-35 63-47 50-46 45-36 45-14 52v48h-128v-48q0-47 14-81t35-63 47-50 46-45 36-45 14-52q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50h-128q0-53 20-99t55-82 81-55 100-20z" />
    </svg>
  ),
  displayName: 'SunQuestionMarkIcon',
});

export default SunQuestionMarkIcon;
