import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AnalyticsLogoIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2048 1024q0 142-36 272t-103 245-160 207-208 160-245 103-272 37q-142 0-272-36t-245-103-207-160-160-208-103-245-37-272q0-26 19-45t45-19q26 0 45 19t19 45q0 124 32 238t90 213 141 182 181 140 214 91 238 32q123 0 237-32t214-90 182-141 140-181 91-214 32-238q0-123-32-237t-90-214-141-182-181-140-214-91-238-32q-26 0-45-19t-19-45q0-26 19-45t45-19q141 0 272 36t245 103 207 160 160 208 103 245 37 272zm-1024 640q-26 0-45-19t-19-45q0-26 19-45t45-19q106 0 199-40t162-110 110-163 41-199q0-106-40-199t-110-162-163-110-199-41q-26 0-45-19t-19-45q0-26 19-45t45-19q133 0 249 50t204 137 137 203 50 250q0 133-50 249t-137 204-203 137-250 50zm-64-832q0-26 19-45t45-19q53 0 99 20t82 55 55 81 20 100q0 26-19 45t-45 19q-26 0-45-19t-19-45q0-27-10-50t-27-40-41-28-50-10q-26 0-45-19t-19-45z" />
    </svg>
  ),
  displayName: 'AnalyticsLogoIcon',
});

export default AnalyticsLogoIcon;
