import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const CalendarSettingsIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2048 128v1064q-57-62-128-107V640H128v1152h898q11 34 25 66t34 62H0V128h384V0h128v128h1024V0h128v128h384zm-128 128h-256v128h-128V256H512v128H384V256H128v256h1792V256zM896 1408H768v-128h128v128zm256-384h128v61q-47 30-88 67h-40v-128zm128-128h-128V768h128v128zm384 0h-128V768h128v128zM512 1408H384v-128h128v128zm-128 128h128v128H384v-128zm128-384H384v-128h128v128zm384-256H768V768h128v128zm-128 640h128v128H768v-128zm128-384H768v-128h128v128zm1142 336l-124 51q6 30 6 61t-6 61l124 51-49 119-124-52q-35 51-86 86l52 124-119 49-51-124q-30 6-61 6t-61-6l-51 124-119-49 52-124q-51-35-86-86l-124 52-49-119 124-51q-6-30-6-61t6-61l-124-51 49-119 124 52q35-51 86-86l-52-124 119-49 51 124q30-6 61-6t61 6l51-124 119 49-52 124q51 35 86 86l124-52 49 119zm-438 304q40 0 75-15t61-41 41-61 15-75q0-40-15-75t-41-61-61-41-75-15q-40 0-75 15t-61 41-41 61-15 75q0 40 15 75t41 61 61 41 75 15z" />
    </svg>
  ),
  displayName: 'CalendarSettingsIcon',
});

export default CalendarSettingsIcon;
