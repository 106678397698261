import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const ProcessMetaTaskIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M951 1037q35-51 86-86l-52-124 119-49 51 124q30-6 61-6t61 6l51-124 119 49-52 124q26 17 47 38t39 48l124-52 49 119-124 51q6 30 6 61t-6 61l124 51-49 119-124-52q-17 26-38 47t-48 39l52 124-119 49-51-124q-30 6-61 6t-61-6l-51 124-119-49 52-124q-51-35-86-86l-124 52-49-119 124-51q-6-30-6-61t6-61l-124-51 49-119 124 52zm457 179q0-40-15-75t-41-61-61-41-75-15q-40 0-75 15t-61 41-41 61-15 75q0 40 15 75t41 61 61 41 75 15q39 0 74-15t61-41 42-62 15-74zM267 809l120-70q-3-18-3-35t3-35l-120-70 64-110 121 69q29-23 60-34V384h128v140q31 11 60 34l121-69 64 110-120 70q3 18 3 35t-3 35l120 70-64 110-121-69q-14 11-28 19t-32 15v140H512V884q-17-6-31-14t-29-20l-121 69-64-110zm309-201q-40 0-68 28t-28 68q0 40 28 68t68 28q40 0 68-28t28-68q0-40-28-68t-68-28zM1920 0v2048H0V0h1920zm-128 128H128v1792h1664V128z" />
    </svg>
  ),
  displayName: 'ProcessMetaTaskIcon',
});

export default ProcessMetaTaskIcon;
