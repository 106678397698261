import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const UnpinIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1472 1152q93 0 174 35t143 96 96 142 35 175q0 93-35 174t-96 143-142 96-175 35q-93 0-174-35t-143-96-96-142-35-175q0-93 35-174t96-143 142-96 175-35zm-320 448q0 66 25 124t68 102 102 69 125 25q47 0 92-13t84-40l-443-443q-26 39-39 84t-14 92zm587 176q26-39 39-84t14-92q0-66-25-124t-69-101-102-69-124-26q-47 0-92 13t-84 40l443 443zm251-1028q-33 33-64 60t-65 47-73 29-90 11q-34 0-65-6l-134 135h-182l278-278 10 3q23 7 46 12t47 6q55 0 104-26l-495-495q-26 49-26 104 0 24 5 47t13 46l3 10-492 491q-24-10-46-19t-44-15-45-9-51-4q-57 0-111 16t-100 49l483 483v182l-192-193-568 569-136 45 45-136 569-568-386-386 45-45q70-70 162-107t191-37q39 0 77 6t77 19l379-379q-6-31-6-65 0-49 10-88t30-74 46-65 61-65l690 690z" />
    </svg>
  ),
  displayName: 'UnpinIcon',
});

export default UnpinIcon;
