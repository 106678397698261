import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const AzureKeyVaultIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M896 821q-70-17-129-55t-101-92-66-120-24-138q0-86 33-161t89-132 132-90T992 0q86 0 161 33t132 89 90 132 33 162q0 72-23 138t-66 119-102 92-129 56v843H896v-256H640v-192h256V821zm96-629q-46 0-87 17t-71 48-48 72-18 87q0 46 17 87t48 71 72 48 87 18q46 0 87-17t71-48 48-72 18-87q0-46-17-87t-48-71-72-48-87-18zm536 315q77 62 138 138t103 163 64 182 23 194q0 119-31 229t-87 207-135 174-175 135-206 88-230 31q-119 0-229-31t-207-87-175-135-135-175-87-206-31-230q0-99 22-194t65-181 103-163 138-139q18 104 72 192-100 96-154 221t-54 264q0 93 24 178t68 161 105 136 136 105 160 68 179 24q93 0 178-24t161-68 136-105 105-136 68-160 24-179q0-138-54-263t-154-222q54-88 72-192z" />
    </svg>
  ),
  displayName: 'AzureKeyVaultIcon',
});

export default AzureKeyVaultIcon;
