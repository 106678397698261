import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const BullseyeIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1024 640q-80 0-149 30t-122 82-83 123-30 149q0 80 30 149t82 122 122 83 150 30q79 0 149-30t122-82 83-122 30-150q0-64-22-128h134q8 32 12 64t4 64q0 106-40 199t-110 162-163 110-199 41q-106 0-199-40t-162-110-110-163-40-199q0-106 40-199t109-162 163-110 199-41q32 0 64 4t64 12v134q-64-22-128-22zm866 155q30 113 30 229 0 123-32 237t-90 214-141 182-181 140-214 91-238 32q-123 0-237-32t-214-90-182-141-140-181-91-214-32-238q0-123 32-237t90-214 141-182 181-140 214-91 238-32q116 0 229 30l-101 101v8q-32-5-64-8t-64-3q-106 0-204 27t-183 78-156 120-120 155-77 184-28 204q0 106 27 204t78 183 120 156 155 120 184 77 204 28q106 0 204-27t183-78 156-120 120-155 77-184 28-204q0-32-3-64t-8-64h8l101-101zm-610-118V390L1664 6v378h378l-384 384h-287l-223 223q4 15 4 33 0 27-10 50t-27 40-41 28-50 10q-27 0-50-10t-40-27-28-41-10-50q0-27 10-50t27-40 41-28 50-10q18 0 33 4l223-223zm128-37h197l128-128h-197V315l-128 128v197z" />
    </svg>
  ),
  displayName: 'BullseyeIcon',
});

export default BullseyeIcon;
