import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const OutlookLogoInverse16Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2048 1005v929q0 24-9 44t-24 36-36 25-45 9H370q-24 0-44-9t-36-24-25-36-9-45v-270H128q-26 0-49-10t-41-27-28-41-10-50V512q0-26 10-49t27-41 41-28 50-10h256V128q0-27 10-50t27-40 41-28 50-10h1280q27 0 50 10t40 27 28 41 10 50v776l102 58q11 7 18 18t8 25zm-896-749v128q26 0 49 10t41 27 28 41 10 50v256h512V255l-640 1zm640 640h-512v423l512-295V896zM512 256v128h512V256H512zm126 1184q95 0 172-31t131-86 83-134 30-173q0-91-28-166t-82-129-127-84-166-30q-97 0-175 30t-133 85-85 134-30 176q0 92 30 167t84 128 129 83 167 30zm-254 480h1429l-558-312q-18 25-44 40t-59 16H384v256zm1536-87v-731l-1-1v2l-639 364v9l640 357zM454 1024q0-45 11-86t35-72 60-50 85-19q51 0 86 19t57 51 31 73 10 87q0 46-10 86t-34 71-59 47-86 18q-49 0-84-18t-57-50-34-72-11-85z" />
    </svg>
  ),
  displayName: 'OutlookLogoInverse16Icon',
});

export default OutlookLogoInverse16Icon;
