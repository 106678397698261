import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const UneditableSolidMirrored12Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M574 994L292 712l422-422 280 284-420 420zm1474 1052l-753-333-420-419-729 729-121-121L1902 25l121 121-730 730 407 411 348 759zM140 562q-32-31-58-60t-44-62-28-70-10-84q0-61 23-113t63-91 93-60T294 0q47 0 83 10t68 28 59 44 60 57L140 562z" />
    </svg>
  ),
  displayName: 'UneditableSolidMirrored12Icon',
});

export default UneditableSolidMirrored12Icon;
