import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const BlockContactIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1301 1110q-27 17-52 35t-49 42q-89-80-200-121t-232-42q-134 0-251 49t-203 136-136 204-50 251H0q0-120 35-232t100-206 156-167 206-114q-55-35-99-82t-76-104-49-118-17-129q0-106 40-199t110-162T569 41 768 0q106 0 199 40t162 110 110 163 41 199q0 66-17 128t-48 119-76 103-100 83q74 26 139 68t123 97zM384 512q0 79 30 149t83 122 122 82 149 31q79 0 149-30t122-83 82-122 31-149q0-79-30-149t-83-122-122-82-149-31q-79 0-149 30t-122 83-82 122-31 149zm1216 640q93 0 174 35t142 96 96 142 36 175q0 93-35 174t-96 142-142 96-175 36q-93 0-174-35t-142-96-96-142-36-175q0-93 35-174t96-142 142-96 175-36zm0 768q47 0 92-13t84-40l-443-443q-26 39-39 84t-14 92q0 66 25 124t69 101 102 69 124 26zm267-144q26-39 39-84t14-92q0-66-25-124t-69-101-102-69-124-26q-47 0-92 13t-84 40l443 443z" />
    </svg>
  ),
  displayName: 'BlockContactIcon',
});

export default BlockContactIcon;
