import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const EmojiDisappointedIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M1024 1280q72 0 149 16t147 48 128 83 92 118l-115 57q-26-51-72-87t-101-61-116-35-112-11q-54 0-113 10t-115 34-101 61-72 89l-115-57q34-69 91-119t128-83 147-47 150-16zm0-1280q141 0 272 36t244 104 207 160 161 207 103 245 37 272q0 141-36 272t-103 245-160 207-208 160-245 103-272 37q-141 0-272-36t-245-103-207-160-160-208-103-244-37-273q0-141 36-272t103-245 160-207 208-160T751 37t273-37zm0 1920q123 0 237-32t214-90 182-141 140-181 91-214 32-238q0-123-32-237t-90-214-141-182-181-140-214-91-238-32q-123 0-237 32t-214 90-182 141-140 181-91 214-32 238q0 123 32 237t90 214 141 182 181 140 214 91 238 32zM767 917q-47-21-97-21-35 0-68 10t-62 28-51 45-38 58l-117-53q22-49 57-89t79-68 94-43 105-16q80 0 151 33l-53 116zm461-116q35-17 73-25t78-8q54 0 104 15t95 44 78 68 58 89l-117 53q-15-32-37-58t-51-44-62-29-69-10q-52 0-98 21l-52-116z" />
    </svg>
  ),
  displayName: 'EmojiDisappointedIcon',
});

export default EmojiDisappointedIcon;
