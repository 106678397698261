import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SharepointAppIcon16Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M2048 1152q0 107-40 200t-111 163-165 109-201 40q-12 83-51 153t-99 121-134 81-159 29q-83 0-158-29t-135-80-98-122-52-153H128q-26 0-49-10t-41-27-28-41-10-50V512q0-26 10-49t27-41 41-28 50-10h289q30-86 84-156t126-121 157-79T960 0q119 0 224 45t183 124 123 183 46 224q0 16-1 32t-3 32q106 0 200 40t164 109 111 162 41 201zM960 128q-65 0-125 18t-114 51-95 81-71 106h597q26 0 49 10t41 27 28 41 10 50v197q29-16 58-29t62-22q8-43 8-82 0-93-35-174t-96-142-142-96-175-36zM624 1440q60 0 115-12t99-42 70-76 26-115q0-55-16-94t-46-69-68-50-85-41q-11-8-35-17t-49-20-45-25-19-35q0-18 12-28t31-17 37-8 32-2q36 0 63 4t52 14 49 24 54 33V646q-55-23-108-30t-114-8q-57 0-114 13t-103 42-75 76-29 113q0 64 27 108t67 74 88 51 87 39 68 36 27 43q0 19-12 30t-29 17-35 7-33 2q-24 0-51-4t-55-13-52-20-44-27l-51-40v231q62 32 128 43t136 11zm464 480q66 0 124-25t101-68 69-102 26-125q0-76-33-143t-95-113v192q0 26-10 49t-27 41-41 28-50 10H774q12 56 41 103t70 81 94 53 109 19zm448-384q79 0 149-30t122-82 83-122 30-150q0-79-30-149t-82-122-123-83-149-30q-71 0-137 25t-119 74v328q51 24 93 59t75 80 54 95 29 107h5z" />
    </svg>
  ),
  displayName: 'SharepointAppIcon16Icon',
});

export default SharepointAppIcon16Icon;
