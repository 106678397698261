import * as React from 'react';
import createSvgIcon from '../utils/createSvgIcon';

const SkypeForBusinessLogo16Icon = createSvgIcon({
  svg: ({ classes }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" className={classes.svg}>
      <path d="M836 795q0 35 25 56t68 35 96 24 110 23 110 32 96 53 68 83 26 123q0 86-36 145t-94 97-131 53-147 17q-50 0-104-7t-106-25-95-47-75-73q-15-22-24-47t-10-51q0-42 28-66t69-25q30 0 49 13t36 34 34 43 42 44 62 33 94 14q29 0 61-8t60-25 45-42 18-60q0-22-8-39t-22-29-32-20-37-13q-63-17-126-32t-127-32q-55-14-99-35t-75-54-48-76-17-103q0-82 34-138t90-92 124-50 141-16q30 0 70 6t83 18 84 30 73 44 53 57 20 71q0 21-8 37t-23 27-32 16-38 6q-25 0-42-10t-34-27-33-35-41-35-59-27-87-11q-28 0-56 6t-51 19-37 36-15 55zm1079 312q64 78 98 171t35 194q0 119-45 224t-124 183-183 123-224 46q-100 0-193-34t-172-99q-21 3-41 4t-42 1q-124 0-238-32t-214-90-181-140-140-181-91-214-32-239q0-21 1-41t4-42q-64-78-98-171T0 576q0-119 45-224t124-183T352 46 576 0q100 0 193 34t172 99q21-3 41-4t42-1q124 0 238 32t214 90 181 140 140 181 91 214 32 239q0 21-1 41t-4 42zm-443 813q93 0 174-35t142-96 96-142 36-175q0-97-36-175t-102-148q3-32 6-62t4-63q0-106-27-204t-78-183-120-156-155-120-184-77-204-28q-32 0-62 3t-63 7q-69-65-147-101t-176-37q-93 0-174 35t-142 96-96 142-36 175q0 97 36 175t102 148q-3 32-6 62t-4 63q0 106 27 204t78 183 120 156 155 120 184 77 204 28q32 0 62-3t63-7q69 65 147 101t176 37z" />
    </svg>
  ),
  displayName: 'SkypeForBusinessLogo16Icon',
});

export default SkypeForBusinessLogo16Icon;
